/// <reference path="../../typings/libraries.d.ts"/>

/********************************
****** Localization related methods ******
********************************/


// ReSharper disable once InconsistentNaming
var Alkami = Alkami || {};
Alkami.Localization = Alkami.Localization || {};

Alkami.Localization.SiteText = {};
Alkami.Localization.includeSiteText = function (siteTextJSON) {
    for (var key in siteTextJSON) {
        if (siteTextJSON.hasOwnProperty(key))
            Alkami.Localization.SiteText[key] = siteTextJSON[key];
    }
}